import {
	dateFormatter,
	getCompany,
	getCurrentUser,
	parseTimestampToTimeZone,
	Roles,
	URLS
} from '@netcurio-ui/common'
import {
	approvedEnrollment,
	FileViewerModal,
	newEnrollment,
	rejectEnrollment as rejectEnrollmentStatus,
	reviewEnrollment,
	StatusInformation,
	statusLoading,
	TrackInformation,
	useNetcurioLoader
} from '@netcurio-ui/components'
import { useQuery } from '@tanstack/react-query'
import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { fetchEnvironment } from '../../../../../api/fetch-environment'
import { EnrollmentInvitation, ErrorWrapper, FileViewerObject } from '../../../../../types'
import { connection } from '../../../../../utilities/connection'
import Constants from '../../../../../utilities/constants'
import { showErrorComponent } from '../../../../../utilities/errorCode'
import { expiredToken } from '../../../../../utilities/expiredToken'
import UserInformation from '../../../../../utilities/userInformation'
import styles from './enrollmentDetail.module.scss'
import EnrollmentFiles from './enrollmentFiles'
import LateralSection from './lateralSection'
import { ApprovedEnrollmentModal } from './Modals/ApproveEnrollmentModal/ApprovedEnrollmentModal'
import { BlacklistApprovalConfirmationModal } from './Modals/BlacklistApprovalConfirmationModal/BlacklistApprovalConfirmationModal'
import { ErrorModal } from './Modals/ErrorModal/ErrorModal'
import { RejectEnrollmentModal } from './Modals/RejectEnrollmentModal/RejectEnrollmentModal'
import * as queries from './queries'

export interface Admin {
	status: string
	fullName: string
	email: string
}

interface fileInformation {
	[index: string]: {
		column: string
		uuid?: string
	}
}

export default function EnrollmentDetailScreen(): JSX.Element {
	const { data: environment } = useQuery({
		queryKey: ['environment'],
		queryFn: fetchEnvironment,
		suspense: true,
		staleTime: Infinity
	})
	const { S3_BUCKET } = environment ? environment : { S3_BUCKET: '' }

	const { COMPANIES_FOLDER } = Constants.FOLDER
	const { t } = useTranslation()
	const { invitation } = useParams() as { invitation: string }
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const isCustomer = UserInformation.getCompanyRole() === Roles.CUSTOMER
	let isMounted = true
	const [supplier, id] = invitation.split('&')
	const [errorMessage, setErrorMessage] = useState<ErrorWrapper | undefined>()
	const [enrollmentInformation, setEnrollmentInformation] = useState<EnrollmentInvitation | undefined>()
	const [adminsInformation, setAdminsInformation] = useState<Admin[]>([])
	const [hideButtons, setHideButtons] = useState(false)
	const [paymentDays, setPaymentDays] = useState<string>('')
	const [customerReference, setCustomerReference] = useState('')
	const [showApproveModal, setShowApproveModal] = useState<boolean>(false)
	const [showRejectModal, setShowRejectModal] = useState<boolean>(false)
	const [showBlackListConfirmationModal, setShowBlackListConfirmationModal] = useState<boolean>(false)
	const [enrollmentFiles, setEnrollmentFiles] = useState<fileInformation>({
		charter: { column: 'incorporation_act' },
		registration: { column: 'rfc_registration' },
		attorney: { column: 'attorney' },
		compliance: { column: 'compliance' },
		ine: { column: 'id_card' },
		address: { column: 'proof_address' },
		account: { column: 'bank_statement' },
		services: { column: 'specialty_services' },
		bankForm: { column: 'signed_bank_data_form' },
		supplierDataFormat: { column: 'supplier_data_format' },
		priceList: { column: 'quotation_price_list' },
		operationNotice: { column: 'operation_notice' },
		cofeprisHealthRecords: { column: 'cofepris_health_records' },
		authorizedDistributionLetter: { column: 'authorized_distribution_letter' },
		healthLicenseControlledMedicine: { column: 'health_license_controlled_medicine' },
		noticeFromHealthManager: { column: 'notice_health_manager' },
		other: { column: 'other_doc' },
		additional: { column: 'additional_doc' }
	})
	const [fileViewer, setFileViewer] = useState<FileViewerObject>({
		open: false,
		titleText: t('documentText'),
		fileSrc: ''
	})

	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()

	useEffect(() => {
		getEnrollmentInvitationData()
		return () => {
			isMounted = false
		}
	}, [])

	useEffect(() => {
		if (isNaN(+paymentDays)) setPaymentDays(paymentDays.substring(0, paymentDays.length - 1))
	}, [paymentDays])

	function getEnrollmentInvitationData() {
		showLoadingSpinner()
		client
			.query({
				query: queries.INVITATION_ENROLLMENT_DETAIL,
				variables: {
					id,
					supplier
				},
				fetchPolicy: 'no-cache'
			})
			.then((result) => {
				const info = result.data.EnrollmentInvitation
				const companiesUnblocked = UserInformation.getCompaniesUnblocked()

				if (!info)
					location.href = `${URLS.ADMINISTRATION}/${Constants.ADMIN.ADMIN_TABS.ENROLLMENTS_TAB}`
				if (
					(isCustomer && info.customer.rfc !== getCompany().rfc) ||
					(!isCustomer && info.supplier.rfc !== getCompany().rfc)
				) {
					let newCompany
					for (const company of companiesUnblocked) {
						if (
							company.rfc === info.customer.rfc &&
							company.roles.some((role: { id: string }) => role.id === Roles.CUSTOMER)
						)
							newCompany = company
						else if (
							company.rfc === info.supplier.rfc &&
							company.roles.some((role: { id: string }) => role.id === Roles.SUPPLIER)
						)
							newCompany = company
					}
					if (newCompany) {
						const user = getCurrentUser()
						UserInformation.setCurrentUser(user, newCompany)
						location.reload()
					} else location.href = URLS.WITHOUT_ACCESS
				}

				if (isMounted) {
					info.created_at = dateFormatter.format(parseTimestampToTimeZone(info.created_at))
					if (info.updated_at && info.updated_at !== info.created_at)
						info.updated_at = dateFormatter.format(parseTimestampToTimeZone(info.updated_at))
					else info.updated_at = undefined
					info.status = info.status.key
					setEnrollmentInformation(info)

					const admins = info.admins.map((admin: any) => ({
						fullName: admin.user.name + ' ' + admin.user.lastname,
						status: admin.status.key,
						email: admin.user.email
					}))
					setAdminsInformation(admins)

					setPaymentDays(info.payment_days || '')
					setCustomerReference(info.customer_reference || '')

					const newFilesState = { ...enrollmentFiles }
					for (const key in newFilesState) {
						const document = result.data.EnrollmentInvitation[newFilesState[key].column]
						if (document) newFilesState[key].uuid = document
					}
					setEnrollmentFiles(newFilesState)
					hideLoadingSpinner()
				}
			})
			.catch(handleError)
	}

	const mapStatuses = useCallback((): StatusInformation[] => {
		const createAt = enrollmentInformation?.created_at
		const updatedAt = enrollmentInformation?.updated_at
		const statuses = {
			[Constants.ENROLLMENT_STATUS.NEW]: [newEnrollment(createAt ? createAt.toString() : '')],
			[Constants.ENROLLMENT_STATUS.REJECTED]: [
				newEnrollment(createAt ? createAt.toString() : ''),
				rejectEnrollmentStatus(updatedAt ? updatedAt.toString() : '')
			],
			[Constants.ENROLLMENT_STATUS.APPROVED]: [
				newEnrollment(createAt ? createAt.toString() : ''),
				approvedEnrollment(updatedAt ? updatedAt.toString() : '')
			],
			[Constants.ENROLLMENT_STATUS.REVIEW]: [
				newEnrollment(createAt ? createAt.toString() : ''),
				reviewEnrollment(updatedAt ? updatedAt.toString() : '')
			],
			DEFAULT: [statusLoading()]
		}
		return statuses[enrollmentInformation?.status] || statuses['DEFAULT']
	}, [enrollmentInformation?.status])

	function showButtons() {
		if (!isCustomer) return false

		if (hideButtons) return false

		if (
			enrollmentInformation?.status !== Constants.ENROLLMENT_STATUS.NEW &&
			enrollmentInformation?.status !== Constants.ENROLLMENT_STATUS.REVIEW
		)
			return false

		let currentAdmin
		for (const admin of adminsInformation) {
			if (admin.email === getCurrentUser()?.email) currentAdmin = admin
		}
		return !(!currentAdmin || currentAdmin.status !== Constants.ENROLLMENT_ADMIN_STATUS.PENDING)
	}

	function hasDocuments() {
		for (const key in enrollmentFiles) {
			if (enrollmentFiles[key].uuid) return true
		}
		return false
	}

	const checkIfProviderIsInBlackList = async () => {
		showLoadingSpinner()
		try {
			const response = await client.query({
				query: queries.IS_BLACKLISTED,
				variables: {
					rfc: supplier
				}
			})
			hideLoadingSpinner()
			if (response?.data?.IsBlacklisted?.is_blacklisted) {
				setShowBlackListConfirmationModal(true)
			} else {
				setShowApproveModal(true)
			}
		} catch (error) {
			hideLoadingSpinner()
			handleError(error as Error)
		}
	}

	function approveEnrollment() {
		setShowApproveModal(false)
		showLoadingSpinner()
		client
			.mutate({
				mutation: queries.APPROVE_ENROLLMENT,
				variables: {
					id,
					supplier,
					payment_days: +paymentDays,
					...(customerReference && { customer_reference: customerReference })
				}
			})
			.then(() => {
				getEnrollmentInvitationData()
			})
			.catch(handleError)
			.finally(() => setShowApproveModal(false))
	}

	function rejectEnrollment() {
		setShowRejectModal(false)
		showLoadingSpinner()
		client
			.mutate({
				mutation: queries.REJECT_ENROLLMENT,
				variables: {
					id,
					supplier
				}
			})
			.then(() => {
				getEnrollmentInvitationData()
			})
			.catch(handleError)
	}

	function handleError(error: Error) {
		if (isMounted) {
			console.error(error)
			const errorCode = showErrorComponent(error)
			if (!expiredToken(errorCode)) {
				setErrorMessage({
					code: showErrorComponent(error),
					message: error.message
				})
			}
			hideLoadingSpinner()
		}
	}

	const mapTrackInformationData = useCallback(() => {
		const trackInformationData = {
			customer_name_enrollments: enrollmentInformation?.customer?.name,
			customer_rfc: enrollmentInformation?.customer?.rfc,
			supplier_name_enrollments: enrollmentInformation?.supplier?.name,
			supplier_rfc: enrollmentInformation?.supplier?.rfc,
			supplier_address: enrollmentInformation?.supplier?.address_line_1,
			tokenNewCompany: enrollmentInformation?.supplier?.state_code,
			countryTokenLabel: enrollmentInformation?.supplier?.country_code,
			zipcodeNewCompany: enrollmentInformation?.supplier?.postal_code,
			industryNewCompany: enrollmentInformation?.supplier?.industry_description,
			createdEnrollment: enrollmentInformation?.created_at + '',
			created_by_text:
				enrollmentInformation?.created_by?.name + ' ' + enrollmentInformation?.created_by?.lastname,
			enrollmentInvitationSupplierEmail: enrollmentInformation?.created_by?.email,
			enrollmentInvitationUpdateDate: enrollmentInformation?.updated_at?.toString() || '',
			payment_days_COMPANIES: paymentDays || '',
			enrollmentInvitationUpdatedBy: enrollmentInformation?.updated_by
				? enrollmentInformation?.updated_by?.name + ' ' + enrollmentInformation?.updated_by?.lastname
				: '',
			customer_reference: enrollmentInformation?.customer_reference || '-'
		}
		if (!isCustomer) {
			delete trackInformationData.enrollmentInvitationUpdatedBy
		}
		return trackInformationData
	}, [enrollmentInformation, paymentDays])

	function setFile(uuid: string) {
		const fileName = `${uuid.toLowerCase()}.pdf`
		const fileSrc = `${S3_BUCKET}${COMPANIES_FOLDER}/${fileName}`
		setFileViewer((state) => ({ ...state, fileSrc, open: true }))
	}

	const closePDFModal = () => setFileViewer((state) => ({ ...state, open: false }))

	return (
		<>
			<div className={styles.fixSpaceTopHeaderEnrollment} />
			<div className={styles.containerTrackTotalMiddle}>
				<div className={styles.totalContainerInfo}>
					<div className={styles.containerEnrollment}>
						<TrackInformation
							id={id}
							title={'enrollmentRequestTitle'}
							statuses={mapStatuses()}
							paymentDays={paymentDays}
							showPaymentDays={showButtons()}
							setPaymentDays={setPaymentDays}
							information={mapTrackInformationData()}
							customerSupplierReference={customerReference}
							setCustomerSupplierReference={setCustomerReference}
							gridTemplateAreas={`'customer_name_enrollments customer_rfc supplier_name_enrollments supplier_rfc'
                        'supplier_address tokenNewCompany countryTokenLabel zipcodeNewCompany'
                        'industryNewCompany createdEnrollment created_by_text enrollmentInvitationSupplierEmail'
                        'enrollmentInvitationUpdateDate ${
							isCustomer ? `enrollmentInvitationUpdatedBy` : `.`
						} payment_days_COMPANIES customer_reference`}
						/>
					</div>
					<EnrollmentFiles
						enrollmentFiles={enrollmentFiles}
						setUUIDFile={setFile}
						hasDocuments={hasDocuments}
					/>
				</div>
				<LateralSection
					id={id}
					supplier={enrollmentInformation?.supplier?.rfc}
					supplierComment={supplier}
					handleError={handleError}
					showButtons={showButtons}
					paymentDays={paymentDays}
					setShowApproveModal={checkIfProviderIsInBlackList}
					setShowRejectModal={setShowRejectModal}
					adminsInformation={adminsInformation}
					setHideButtons={setHideButtons}
				/>
			</div>
			<FileViewerModal onClose={closePDFModal} {...fileViewer} />
			<ErrorModal open={!!errorMessage?.code} errorGraphQlMessage={errorMessage?.message} />
			<ApprovedEnrollmentModal
				open={showApproveModal}
				onAccept={approveEnrollment}
				onClose={() => setShowApproveModal(false)}
			/>
			<RejectEnrollmentModal
				open={showRejectModal}
				onReject={rejectEnrollment}
				onClose={() => setShowRejectModal(false)}
			/>
			<BlacklistApprovalConfirmationModal
				open={showBlackListConfirmationModal}
				onAccept={() => {
					setShowBlackListConfirmationModal(false)
					setShowApproveModal(true)
				}}
				onClose={() => setShowBlackListConfirmationModal(false)}
			/>

			{/* TODO: In a future refactor, add RejectPendingMeessageModal to Enrollment Detail. Since this component is not refactored and is dependant of Prop-Drilling through many layers, adding said modal would make the component more complex */}
		</>
	)
}
